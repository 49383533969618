import { Chart1 } from 'iconsax-react';
import { CallAdd } from 'iconsax-react';

// icons
const icons = {
    navigation: Chart1,
    CalendarTick: CallAdd,
};


const Analytics = {
      id: "Analytics",
      title: "Analytics",
      icon: icons.navigation,
      type: "group",
      children: [
        {
              id: "all-analytics",
              title: "Analytics",
              type: "item",
              url: "/all-leads",
              icon: icons.navigation,
              breadcrumbs: true,
            },
        {
              id: "all-leads",
              title: "Leads Management",
              type: "item",
              url: "/Analytics/all-leads",
              icon: icons.CalendarTick,
              breadcrumbs: true,
            },
      ],
    };

export default Analytics;
