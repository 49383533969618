import { Buildings, Buildings2 } from 'iconsax-react';
import { Building3 } from 'iconsax-react';

// icons
const icons = {
    navigation: Buildings,
    Building3: Building3,
    Buildings2: Buildings2,
};


const pre_Construction = {
      id: "Pre-Construction",
      title: "Listing",
      icon: icons.navigation,
      type: "group",
      children: [
        {
              id: "all-preconstruction",
              title: "Pre Construction",
              type: "item",
              url: "/preconstruction/all-preconstruction",
              icon: icons.navigation,
              breadcrumbs: true,
            },
        {
              id: "all-custom_listings",
              title: "Custom Listing / Exclusive",
              type: "item",
              url: "/custom_listings/all-custom_listings",
              icon: icons.Building3,
              breadcrumbs: true,
            },
        {
              id: "all-mls_exclusive",
              title: "MLS Exclusive",
              type: "item",
              url: "/mls_exclusive/all-mls_exclusive",
              icon: icons.Buildings2,
              breadcrumbs: true,
            },
      ],
    };

export default pre_Construction;
