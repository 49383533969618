import { DocumentText } from 'iconsax-react';

// icons
const icons = {
    navigation: DocumentText,
};

// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const blogs = true
  ? {
      id: "Blogs",
      title: "Blogs",
      icon: icons.navigation,
      type: "group",
      children: [
        {
              id: "all-Blogs",
              title: "Blogs",
              type: "item",
              url: "/blogs/all-blogs",
              icon: icons.navigation,
              breadcrumbs: true,
            },
      ].filter(Boolean),
    }
  : null;

export default blogs;
