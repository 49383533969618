import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import AllPreConstruction from "pages/pre-construction/AllPreConstruction";
import CreatePreconstruction from "pages/pre-construction/CreatePreConstruction";
import AllAppointments from "pages/Appointments/AllAppointments";
import ContactUs from "pages/contactUs/ContactUs";
import Leads from "pages/Analytics/Allleads";

import AllCustomProperty from "pages/custom-property/AllCustomProperty";
import CreateCustomProperty from "pages/custom-property/CreateCustomProperty";
import Allusers from "pages/user-management/allusers";
import SellmyProperty from "pages/SellMyProperty/SellmyProperty";
import RegisterUser from "pages/user-management/registerUser";
import AllMLSExclusive from "pages/MLSExclusive/AllMLSExclusive";
import CreateMLSExclusive from "pages/MLSExclusive/CreateMLSExclusive";
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

const Blogs = Loadable(
  lazy(() => import("pages/blogs/AllBlogs"))
);
const CreateBlogs = Loadable(
  lazy(() => import("pages/blogs/CreateBlog"))
);

let permissionUser = localStorage.getItem("data");

let decryptedData = null;
if (permissionUser) {
  try {
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
  } catch (error) {
    localStorage.clear();
    window.location.reload();
  }
}

const permissionsAssrray =
  decryptedData && JSON.parse(decryptedData)?.user_type;


const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children:
        permissionsAssrray == "bank_user"
          ? []
          : [
            {
              path: "/",
              element: <DashboardDefault />,
            },
            {
              path: "dashboard",
              element: <DashboardDefault />,
            },
            {
              path: "*",
              element: <DashboardDefault />,
            },
          ],
    },

    {
      path: "blogs",
      element: <MainLayout />,
      children: [
        {
          path: "all-blogs",
          element: <Blogs />,
        },
        {
          path: "create-blogs",
          element: <CreateBlogs />,
        }
      ],
    },
    {
      path: "Analytics",
      element: <MainLayout />,
      children: [
        {
          path: "all-leads",
          element: <Leads />,
        },
      ],
    },
    {
      path: "users_management",
      element: <MainLayout />,
      children: [
        {
          path: "users_management",
          element: <Allusers />,
        },
        {
          path: "create_user",
          element: <RegisterUser />,
        },
      ],
    },
    {
      path: "preconstruction",
      element: <MainLayout />,
      children: [
        {
          path: "all-preconstruction",
          element: <AllPreConstruction />,
        },
        {
          path: "create-preconstruction",
          element: <CreatePreconstruction />,
        }
      ],
    },
    {
      path: "mls_exclusive",
      element: <MainLayout />,
      children: [
        {
          path: "all-mls_exclusive",
          element: <AllMLSExclusive />,
        },
        {
          path: "create-mls_exclusive",
          element: <CreateMLSExclusive />,
        }
      ],
    },
    {
      path: "custom_listings",
      element: <MainLayout />,
      children: [
        {
          path: "all-custom_listings",
          element: <AllCustomProperty />,
        },
        {
          path: "create-custom_listings",
          element: <CreateCustomProperty />,
        }
      ],
    },
    {
      path: "appointments",
      element: <MainLayout />,
      children: [
        {
          path: "all-appointments",
          element: <AllAppointments />,
        }
      ],
    },
    {
      path: "sellmyproperty",
      element: <MainLayout />,
      children: [
        {
          path: "all-sellmyproperty",
          element: <SellmyProperty />,
        }
      ],
    },
    {
      path: "contact_us",
      element: <MainLayout />,
      children: [
        {
          path: "all-contact_us",
          element: <ContactUs />,
        }
      ],
    },

  ].filter(Boolean),
};

export default MainRoutes;
