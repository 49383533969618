import { ALL_PRECONSTRUCTION_GET } from "../../constant/ActionType";

export default function GetAllPreconstruction(
  state = {
    allPreconstructionData: [],
  },
  action
) {
  switch (action.type) {
    case ALL_PRECONSTRUCTION_GET:
      return {
        ...state,
        allPreconstructionData: action?.data?.data,
      };

    default:
  }

  return state;
}