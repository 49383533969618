import { ALL_LEADS_GET, GET_ALL_USERS, GET_ALL_SELL_PROPERTY } from "../../constant/ActionType";

export default function GetAllLeads(
  state = {
    allLeadsData: [],
    allUsersData: [],
    allSellMyPropertyData: [],
  },
  action
) {
  switch (action.type) {
    case ALL_LEADS_GET:
      return {
        ...state,
        allLeadsData: action?.data,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        allUsersData: action?.data?.data,
      };
      case GET_ALL_SELL_PROPERTY:
        return {
          ...state,
          allSellMyPropertyData: action?.data?.data,
        };
    default:
  }

  return state;
}