import { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Image } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { dispatch } from "store/index";
import { GetAllSellProperty } from "store/action/index";
import GetColumnSearchProps from "helper/searchHelper";

const SellMyProperty = ({ allSellMyPropertyData }) => {


  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    dispatch(GetAllSellProperty({ setLoading }));
  }, []);

  useEffect(() => {
    // Map through data and add key using id field
    const formattedData = (allSellMyPropertyData && allSellMyPropertyData.length > 0) ? allSellMyPropertyData?.map(blog => ({
      ...blog,
      key: blog.id, // Use id as the key
    })) : [];

    setDataSource(formattedData);
  }, [allSellMyPropertyData]);


  const columns = [
    {
        title: "Blog Image",
        dataIndex: "images",
        key: "images",
        render: (text, record) => (
          <span>
            {
(text && text.length > 0) ? 
JSON.parse(text).map((e,i)=>{
    return(

        <Image
        width={50}
        alt={i}
        src={`https://www.luxlifemiamirealestate.com/api/storage/app/public/${e}`}
        />
    )
}) : 'N/A'
            }
         
          </span>
        ),
      },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...GetColumnSearchProps("name"),
      render: (text, record) => {
        return (
          <div >
<p target="_blank">{text ? text : record?.first_name ? (record?.first_name || '') + ' ' + (record?.last_name || '') : 'Name not Found'}</p>
              </div>
        )
      },
    },
    
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...GetColumnSearchProps("email"),
        render: (text, record) => {
          return (
            <div >
  <p target="_blank">{text ? text : 'Email not Found'}</p>
                </div>
          )
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        ...GetColumnSearchProps("phone"),
        render: (text, record) => {
          return (
            <div >
  <p target="_blank">{text ? (text || '') : 'N/A'}</p>
                </div>
          )
        },
      },
      {
        title: "Topic",
        dataIndex: "topic",
        key: "topic",
        ...GetColumnSearchProps("topic"),
        render: (text, record) => {
          return (
            <div >
  <p target="_blank">{text ? (text || '') : 'N/A'}</p>
                </div>
          )
        },
      },
      {
        title: "Bed",
        dataIndex: "beds",
        key: "beds",
        ...GetColumnSearchProps("beds"),
        render: (text, record) => {
          return (
            <div >
  <p target="_blank">{text ? (text || '') : 'N/A'}</p>
                </div>
          )
        },
      },
      {
        title: "Bath",
        dataIndex: "baths",
        key: "baths",
        ...GetColumnSearchProps("baths"),
        render: (text, record) => {
          return (
            <div >
  <p target="_blank">{text ? (text || '') : 'N/A'}</p>
                </div>
          )
        },
      },
      {
        title: "Message",
        dataIndex: "messages",
        key: "messages",
        ...GetColumnSearchProps("messages"),
        render: (text, record) => {
          return (
            <div >
  <p target="_blank">{text ? (text || '') : 'N/A'}</p>
                </div>
          )
        },
      }
  ];

 

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              Sell My Property
            </span>
          </Grid>
        </Grid>
    
      </div>
      <MainCard>
        <Table
          style={{ overflowX: "auto" }}
          pagination={false}
          dataSource={dataSource} columns={columns} loading={loading}
        />
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => ({
    allSellMyPropertyData: state?.GetAllLeads?.allSellMyPropertyData,
});

export default connect(mapStateToProps)(SellMyProperty);
