// material-ui

import MainCard from 'components/MainCard';
import ThemeInput from 'components/inputs/ThemeInput';
import { useEffect, useState } from 'react';
import { Grid, InputLabel, Button } from '@mui/material';
import { Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import {
  toast
} from 'react-toastify';
import { dispatch } from 'store/index';
import { CreatePreConstructionAll, EditPreConstruction, GetCustomListingAll } from 'store/action/index';
import { useLocation } from "react-router-dom";
import MultipleSelect from 'components/inputs/MultipleSelect';
import { connect } from 'react-redux';
const CryptoJS = require("crypto-js");


// ==============================|| DASHBOARD - DEFAULT ||============================== //

const CreatePreconstruction = ({ allCustomListingData }) => {
  const location = useLocation()

  const [title, setTitle] = useState(location.state !== null ? location.state.title : "");
  const [bed, setBed] = useState(location.state !== null ? location.state.bed : "");
  const [bath, setBath] = useState(location.state !== null ? location.state.bath : "");
  const [address, setAddress] = useState(location.state !== null ? location.state.address : "");
  const [price_range, setPrice_Range] = useState(location.state !== null ? location.state.price_range : "");
  const [description, setDescription] = useState(location.state !== null ? location.state.description : "");
  const [locations, setLocations] = useState(location.state !== null ? location.state.location : "");
  const [link, setLink] = useState(location.state !== null ? location.state.link : "");
  const [website, setWebsite] = useState(location.state !== null ? location.state.website : "");
  const [residence, setResidence] = useState(location.state !== null ? location.state.residence : "");

  const [requirments, setrequirments] = useState(location.state !== null && location.state.features !== "" ? JSON.parse(location.state.features) : []);
  const [listing, setlisting] = useState(location.state !== null && location.state.resident !== null ? JSON.parse(location.state.resident) : []);
console.log('location.state',location.state);

  const [image, setImage] = useState(
    location.state?.photos && JSON.parse(location.state?.photos).length !== 0 && JSON.parse(location.state.photos).map((img, index) => ({
      uid: index.toString(),
      name: img,
      status: "done",
      url: img.includes("https://www.luxlifemiamirealestate.com/") ? img :'https://www.luxlifemiamirealestate.com/api/storage/app/public/'+img,
    })) || []
  );

  const [docs, setDocs] = useState(
    location.state?.docs && JSON.parse(location.state.docs).length > 0
      ? JSON.parse(location.state.docs).map((doc, index) => ({
          uid: index.toString(),
          name: doc,
          status: "done",
          url: doc.includes("https://www.luxlifemiamirealestate.com/")
            ? doc
            : "https://www.luxlifemiamirealestate.com/api/storage/app/public/" + doc,
        }))
      : []
  );
  
  const [floor_plan, setfloorplan] = useState(
    location.state?.floor_plan && JSON.parse(location.state.floor_plan).length > 0
      ? JSON.parse(location.state.floor_plan).map((plan, index) => ({
          uid: index.toString(),
          name: plan,
          status: "done",
          url: plan.includes("https://www.luxlifemiamirealestate.com/")
            ? plan
            : "https://www.luxlifemiamirealestate.com/api/storage/app/public/" + plan,
        }))
      : []
  );
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(GetCustomListingAll({ setLoading }));
  }, []);

  const handleImageUpload = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
  
    let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      '2d84n0OiymE6eQtvCsdfdstett5zQmm2OsP'
    ).toString(CryptoJS.enc.Utf8);
  }
  const headers = {
      Authorization: `Bearer ${NewToekn}`,
    }

    try {
      const response = await fetch('https://www.luxlifemiamirealestate.com/api/api/DBProperty/fileupload', {
        method: 'POST',
        body: formData,
        headers
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log('responseData',responseData);
        
        const uploadedFile = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: 'https://www.luxlifemiamirealestate.com/api/storage/app/public/'+ responseData.data,
        };
        setImage((prev) => [...prev, uploadedFile]);
        onSuccess();
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      onError(error);
      toast.error('File upload failed');
    }
  };
  

  // Handle image removal
  const handleImageRemove = (file) => {
    setImage((prev) => prev.filter((img) => img.uid !== file.uid));
  };

  const handleDocsUpload = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
  
    let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      '2d84n0OiymE6eQtvCsdfdstett5zQmm2OsP'
    ).toString(CryptoJS.enc.Utf8);
  }
  const headers = {
      Authorization: `Bearer ${NewToekn}`,
    }

    try {
      const response = await fetch('https://www.luxlifemiamirealestate.com/api/api/DBProperty/fileupload', {
        method: 'POST',
        body: formData,
        headers
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log('responseData', responseData);
  
        const uploadedFile = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: 'https://www.luxlifemiamirealestate.com/api/storage/app/public/' + responseData.data,
        };
  
        // Replace the current file with the new one
        setDocs([uploadedFile]);
        onSuccess();
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      onError(error);
      toast.error('File upload failed');
    }
  };
  
  // Handle single floor plan upload
  const handleFloorPlanUpload = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
  
    let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      '2d84n0OiymE6eQtvCsdfdstett5zQmm2OsP'
    ).toString(CryptoJS.enc.Utf8);
  }
  const headers = {
      Authorization: `Bearer ${NewToekn}`,
    }

    try {
      const response = await fetch('https://www.luxlifemiamirealestate.com/api/api/DBProperty/fileupload', {
        method: 'POST',
        body: formData,
        headers
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log('responseData', responseData);
  
        const uploadedFile = {
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: 'https://www.luxlifemiamirealestate.com/api/storage/app/public/' + responseData.data,
        };
  
        // Replace the current file with the new one
        setfloorplan([uploadedFile]);
        onSuccess();
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      onError(error);
      toast.error('File upload failed');
    }
  };
  
  // Handle document removal
  const handleDocsRemove = (file) => {
    setDocs([]);
  };
  
  // Handle floor plan removal
  const handleFloorPlanRemove = (file) => {
    setfloorplan([]);
  };

  const handleSubmit = () => {
    if (title !== "") {


      const imageUrls = image.map((img) => img.url);
      const docsUrls = docs.map((img) => img.url);
      const floorplanUrls = floor_plan.map((img) => img.url);

      if (location.state !== null) {
        
        const payload = {
          title,
          bed,
          bath,
          address,
          price_range,
          description,
          link,
          location: locations,
          features: requirments && requirments.length > 0 ? requirments.join(", ") : "",
          website,
          residence,
          resident: listing,

          id: location.state?.link,
          ...(imageUrls && { photos: imageUrls, }),
          ...(docs && { docs: docsUrls, }),
          ...(floor_plan && { floor_plan: floorplanUrls, }),

        }

        dispatch(EditPreConstruction({
          payload, setLoading: setLoading,
        }))

      } else {
        const payload = {
          title,
          bed,
          bath,
          address,
          link,
          price_range,
          description,
          location: locations,
          features: requirments && requirments.length > 0 ? requirments.join(", ") : "",
          website,
          residence,
          photos: imageUrls,
          docs: docsUrls,
          floor_plan:floorplanUrls,
          resident:listing,
      
        };
        if (!image) {
          toast.error("Image is required")
          return false
        }
        dispatch(CreatePreConstructionAll({ payload, setLoading: setLoading, }))
      }

    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: 'toastStyle',
      });
    }


  }


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              {location.state === null ? "Create" : "Edit"} Pre Construction
            </span>
          </Grid>
        </Grid>
      </div>
      <MainCard>
        <Spin spinning={loading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='row'>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="title" value={title} onChange={(e) => setTitle(e.target.value)} type="text" name="Title*" placeholder="Eg. Title" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="link" value={link} onChange={(e) => setLink(e.target.value)} type="text" name="Link*" placeholder="Eg. mercedes-benz" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="title" value={address} onChange={(e) => setAddress(e.target.value)} type="text" name="Address*" placeholder="Eg. Address" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput id="title" value={bed} onChange={(e) => setBed(e.target.value)} type="text" name="Bed*" placeholder="Eg. Bed" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput id="title" value={bath} onChange={(e) => setBath(e.target.value)} type="text" name="Bath*" placeholder="Eg. Bath" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput id="title" value={website} onChange={(e) => setWebsite(e.target.value)} type="text" name="Website*" placeholder="Eg. Website" />
                </div>
                <div className='col-md-2 my-2'>
                  <ThemeInput id="title" value={residence} onChange={(e) => setResidence(e.target.value)} type="text" name="Residence*" placeholder="Eg. Residence" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="title" value={price_range} onChange={(e) => setPrice_Range(e.target.value)} type="text" name="Price Range*" placeholder="Eg. Price Range" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="title" value={description} onChange={(e) => setDescription(e.target.value)} type="text" name="Description*" placeholder="Eg. Description" />
                </div>
                <div className='col-md-4 my-2'>
                  <ThemeInput id="title" value={locations} onChange={(e) => setLocations(e.target.value)} type="text" name="Location URL*" placeholder="Eg. Location URL" />
                </div>

                <div className='col-md-4 my-2'>
                  <MultipleSelect
                    mode="tags"
                    name="Features*"
                    placeholder="Enter Features and hit Enter"
                    id="names"
                    onChange={(e) => setrequirments(e)}
                    defaultValue={requirments}
                  />
                </div>
                <div className='col-md-4 my-2'>
                  <MultipleSelect
                    mode="multiple"
                    name="Custom Listing*"
                    placeholder="Enter Custom Listing"
                    id="listings"
                    onChange={(e) => setlisting(e)}
                    defaultValue={listing}
                    options={allCustomListingData}
                  />
                </div>



                <div className='col-md-2 my-2'>
                  <InputLabel htmlFor="image-option">Images*</InputLabel>

                  <Upload
                    customRequest={handleImageUpload}
                    fileList={image}
                    onRemove={handleImageRemove}
                    listType="picture"
                    showUploadList={true}
                    multiple
                  >
                    <Button fullWidth
                      className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
                    >
                      <UploadOutlined style={{ marginRight: 10 }} /> Upload Images
                    </Button>
                  </Upload>
                </div>
                <div className='col-md-2 my-2'>
                  <InputLabel htmlFor="image-option">Docs*</InputLabel>

                  <Upload
                    customRequest={handleDocsUpload}
                    fileList={docs}
                    onRemove={handleDocsRemove}
                    listType="text"
                    showUploadList={true}
                    maxCount={1}
                  >
                    <Button fullWidth
                      className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
                    >
                      <UploadOutlined style={{ marginRight: 10 }} /> Upload Docs
                    </Button>
                  </Upload>
                </div>

                <div className='col-md-2 my-2'>
                  <InputLabel htmlFor="image-option">Floor Plan*</InputLabel>

                  <Upload
                    customRequest={handleFloorPlanUpload}
                    fileList={floor_plan}
                    onRemove={handleFloorPlanRemove}
                    listType="text"
                    showUploadList={true}
                    maxCount={1}
                  >
                    <Button fullWidth
                      className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
                    >
                      <UploadOutlined style={{ marginRight: 10 }} /> Upload Floor Plan
                    </Button>
                  </Upload>
                </div>


              </div>


              <div className='my-3' style={{ textAlign: "end" }}>
                <Button
                  onClick={handleSubmit}
                  disableElevation
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {location.state === null ? "Add Pre Construction" : "Update Pre Construction"}
                </Button>

              </div>
            </Grid>
          </Grid>

        </Spin>
      </MainCard>

    </>
  );
};


const mapStateToProps = (state) => ({
  allCustomListingData: state?.GetAllCustomListing?.allCustomListingData,
});

export default connect(mapStateToProps)(CreatePreconstruction);