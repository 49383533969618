import {
    CONTACT_US_GET
} from "../../constant/ActionType";

export default function AllContactUs(state = {
    allcontacts: [],

}, action) {
    switch (action.type) {
        case CONTACT_US_GET: 
            return {
                ...state,
                allcontacts: action?.data?.data,
                
            };

        default:
    }

    return state;
}