// material-ui

import MainCard from 'components/MainCard';
import { useState } from 'react';
import { Grid, Button } from '@mui/material';
import { Spin } from 'antd';
import {
    toast
} from 'react-toastify';
import { dispatch } from 'store/index';
import { CreateMLSAll } from 'store/action/index';
import MultipleSelect from 'components/inputs/MultipleSelect';

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const CreateMLSExclusive = () => {


  const [requirments, setrequirments] = useState([]);
 
  
  const [loading, setLoading] = useState(false);

 
  const handleSubmit = () => {
    if (requirments.length !== 0) {
        const payload = {      
          listing_ids: requirments
        };
        
        dispatch(CreateMLSAll({ payload, setLoading: setLoading, }))

    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: 'toastStyle',
      });
    }


  }


  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              Create MLS Exclusive
            </span>
          </Grid>
        </Grid>
      </div>
      <MainCard>
        <Spin spinning={loading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='row'>
           <div className='col-md-4 my-2'>
                  <MultipleSelect
                    mode="tags"
                    name="MLS IDs*"
                    placeholder="Enter MLS IDs and hit Enter"
                    id="names"
                    onChange={(e) => setrequirments(e)}
                    defaultValue={requirments}
                  />
                </div>
         


              </div>


              <div className='my-3' style={{ textAlign: "end" }}>
                <Button
                  onClick={handleSubmit}
                  disableElevation
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                 Add MLS Exclusive
                </Button>

              </div>
            </Grid>
          </Grid>

        </Spin>
      </MainCard>

    </>
  );
};

export default CreateMLSExclusive;