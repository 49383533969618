// third-party
import { combineReducers } from "redux";

// project-imports
import menu from "./menu";
import AllContactUs from "./AllContacts/AllContactUs";
import GetAllBlogs from "./allBlogs/AllBlogs";
import GetAllLeads from "./allLeads/allLeads";

import GetAllPreconstruction from "./PreConstruction/AllPreconstruction";
import GetAllAppointments from "./allAppointments.js/allAppointments";
import GetAllCustomListing from "./CustomListing/AllCustomListing";



// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    menu,
  AllContactUs,
  GetAllPreconstruction,
  GetAllBlogs,  
  GetAllLeads,  
  GetAllCustomListing,
  GetAllAppointments
});

export default reducers;