import React, { useState } from 'react';
import { Grid, InputLabel, Stack } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import { Select } from 'antd';

const SingleSelect = (props) => {
  let {
    mode,
    name,
    placeholder,
    id,
    allowClear,
    errorText,
    error,
    disabled,
    defaultValue,
    onChange,
    options,
  } = props;

  // Transform options to be in {value, label} format
  const transformedOptions = options && options.length > 0 
    ? options.map(option => ({
        value: option, 
        label: option,
    }))
    : [];

  // If defaultValue is a string (e.g., 'Miami'), find the corresponding option
  const formattedDefaultValue = options.includes(defaultValue) 
    ? { value: defaultValue, label: defaultValue } 
    : null;

  return (
    <Grid item xs={12}>
      <Stack spacing={1}>
        <InputLabel htmlFor={id}>{name}</InputLabel>

        <Select
        
          id={id}
          showSearch
          allowClear={allowClear}
          placeholder={placeholder}
          value={formattedDefaultValue} // Use formatted default value
          disabled={disabled}
          onChange={onChange}
          options={transformedOptions}
          style={{ flex: 1 }}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
        />

        {error ? (
          <FormHelperText style={{ margin: '0px 3%', color: 'red', fontSize: 10 }}>
            {errorText}
          </FormHelperText>
        ) : (
          <div></div>
        )}
      </Stack>
    </Grid>
  );
};

SingleSelect.defaultProps = {
  errorText: '',
  error: false,
  options: [],
  value: [], // Ensure value is always initialized
};

export default SingleSelect;
