import { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Image } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { dispatch } from "store/index";
import { GetLeadsAll } from "store/action/index";
import { Link } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
import GetColumnSearchProps from "helper/searchHelper";
import * as XLSX from 'xlsx';

const AllLeads = ({ allLeadsData }) => {
    const transformedArray = [
        ...(allLeadsData?.selling_property?.data?.map(item => ({ 
            ...item, 
            type: allLeadsData?.selling_property.type 
        })) || []),
        ...(allLeadsData?.newsletter?.data?.map(item => ({ 
            ...item, 
            type: allLeadsData?.newsletter.type 
        })) || []),
        ...(allLeadsData?.user?.data?.map(item => ({ 
            ...item, 
            type: allLeadsData?.user.type 
        })) || [])
    ];


    const transformedArrayExcel = allLeadsData.map(item => ({
      name: item?.name || `${item?.first_name || ''} ${item?.last_name || ''}`.trim() || 'N/A',
      email: item.email || 'N/A',
      phone: item.phone || 'N/A',
      type: item.type || 'N/A'
  }));

  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  useEffect(() => {
    dispatch(GetLeadsAll({ setLoading }));
  }, []);

  useEffect(() => {
    // Map through data and add key using id field
    const formattedData = allLeadsData?.map(blog => ({
      ...blog,
      key: blog.id, // Use id as the key
    })) ?? [];

    setDataSource(formattedData);
  }, [allLeadsData]);

  const handleExpand = (expanded, record) => {
    setExpandedRowKeys((prevExpandedRowKeys) => {
      if (expanded) {
        // If the row is expanded, add its key to expandedRowKeys
        return [...prevExpandedRowKeys, record.key];
      } else {
        // If the row is collapsed, remove its key from expandedRowKeys
        return prevExpandedRowKeys.filter((key) => key !== record.key);
      }
    });
  };



//   const handleEdit = (data) => {
//     navigate("/preconstruction/create-preconstruction", { state: data });
//   };

  const columns = [
  
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...GetColumnSearchProps("name"),
      render: (text, record) => {
        return (
          <div >
<p target="_blank">{text ? text : record?.first_name ? (record?.first_name || '') + ' ' + (record?.last_name || '') : 'Name not Found'}</p>
              </div>
        )
      },
    },
    
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...GetColumnSearchProps("email"),
        render: (text, record) => {
          return (
            <div >
  <p target="_blank">{text ? text : 'Email not Found'}</p>
                </div>
          )
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        ...GetColumnSearchProps("phone"),
        render: (text, record) => {
          return (
            <div >
  <p target="_blank">{text ? (text || '') : 'Phone not Found'}</p>
                </div>
          )
        },
      },
    {
      title: "Data Type",
      dataIndex: "type",
      key: "type",
      ...GetColumnSearchProps("type"),

    },
 
  ];

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(transformedArrayExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");

    // Save as Excel file
    XLSX.writeFile(workbook, "LuxlifeLeadsData.xlsx");
};

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All Leads Data
            </span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Link onClick={exportToExcel} style={{ textDecoration: 'none', color: '#FF264C' }}>
              <span
                disableElevation
                className="px-2 flex" style={{alignItems:"center"}}
                size="large"
              >
                <span className="mx-2">
                  <IoMdAddCircle color="#FF264C" size={26} />
                </span>
                Download Excel
              </span>
            </Link>
          </Grid>

        </Grid>
      </div>
      <MainCard>
        <Table
          style={{ overflowX: "auto" }}
          pagination={false}

          dataSource={dataSource} columns={columns} loading={loading}

        //   expandable={{
        //     expandedRowRender: (record) => (
        //       <div className="mb-4">
        //       <p><strong>Address:</strong> {record?.email}</p>
            
        //     </div>
                
        //     ),
        //     rowExpandable: () => true,
        //     onExpand: handleExpand,
        //     expandedRowKeys: expandedRowKeys,
        //   }}

        />
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => ({
    allLeadsData: state?.GetAllLeads?.allLeadsData,
});

export default connect(mapStateToProps)(AllLeads);
