// material-ui

import MainCard from 'components/MainCard';
import PasswordInput from 'components/inputs/PasswordInput';
import ThemeInput from 'components/inputs/ThemeInput';
import { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import { Spin } from 'antd';
import {
  toast
} from 'react-toastify';
import { dispatch } from 'store/index';
import { CreateAddUsers } from 'store/action/index';
import { connect } from 'react-redux';
import { useLocation } from "react-router-dom";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const RegisterUser = ({  }) => {
  const location = useLocation()
  const [userName, setUserName] = useState(location.state !== null ? location.state.username : "");
  const [fullName, setFullName] = useState(location.state !== null ? location.state.name : "");

  const [email, setEmail] = useState(location.state !== null ? location.state.email : "");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false)


  const handleSubmit = () => {
    if (
      userName !== "" &&
      fullName !== "" &&
      email !== "")
      {

        if (!password && !confirmPassword) {
          toast.error("Password and Confirm Password is required");
          return false;
        } else if (password != confirmPassword) {
          toast.error("Passwords do not match with the confirm password");
          return false;
        }
        const payload = {
          first_name: userName,
          last_name: fullName,
          email: email,
          password:password,
          c_password: confirmPassword,
        }
        dispatch(CreateAddUsers({ payload, setLoading }))
      }

     else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: 'toastStyle',
      });
    }
  }


  return (
    <>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        height: "75px",
        borderRadius: "12px",
        marginBottom: "20px",
        padding: "0 16px",
        backgroundColor: "#fff",
        border: '1px solid',
        borderColor: 'rgba(228,162,49,0.8)',
      }}
    >
      <Grid container alignItems="center" justifyContent="flex-start">
        <Grid item>
          <span
            style={{
              fontSize: "22px",
              fontWeight: "600",
            }}
          >
            {location.state === null ? "Create" : "Edit"} User
          </span>
        </Grid>
      </Grid>
    </div>
    <MainCard>
        <Spin spinning={loading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className='row'>
              <div className='col-md-4 my-2'>
                <ThemeInput id="Username" value={userName} onChange={(e) => setUserName(e.target.value)} type="text" name="First Name*" placeholder="Enter First Name" />
              </div>
              <div className='col-md-4 my-2'>
                <ThemeInput id="FullName" value={fullName} onChange={(e) => setFullName(e.target.value)} type="text" name="Last Name*" placeholder="Enter Last Name" />
              </div>
              <div className='col-md-4 my-2'>
                <ThemeInput id="email" value={email} onChange={(e) => setEmail(e.target.value)} type="text" name="Email*" placeholder="Enter Email" />
              </div>
            </div>
            <div className='row'>

              {location.state === null &&
                <>
                  <div className='col-md-4 my-2'>
                    <PasswordInput id="password" name="Password*" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                  </div>
                  <div className='col-md-4 my-2'>
                    <PasswordInput id="confirmpassword" name="Confirm Password*" placeholder="Enter Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                  </div>
                </>
              }

            </div>


            <div className='my-3' style={{ textAlign: "end" }}>
              <Button
                onClick={handleSubmit}
                disableElevation
                size="medium"
                type="submit"
                variant="contained"
                color="primary"
                style={{ color: "white" }}
              >
                {location.state === null ? "Add New" : "Update"} User
              </Button>

            </div>
          </Grid>
        </Grid>

      </Spin>
    </MainCard>
</>
  );
};

const mapStateToProps = (state) => {
  return {

  };
};


export default connect(mapStateToProps)(RegisterUser);