import { ALL_CUSTOM_LISTING_GET, ALL_MLS_GET } from "../../constant/ActionType";

export default function GetAllCustomListing(
  state = {
    allCustomListingData: [],
    allMLSData: [],
  },
  action
) {
  switch (action.type) {
    case ALL_CUSTOM_LISTING_GET:
      return {
        ...state,
        allCustomListingData: action?.data?.data,
      };
    case ALL_MLS_GET:
      return {
        ...state,
        allMLSData: action?.data?.data,
      };

    default:
  }

  return state;
}