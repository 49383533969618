import { useState } from "react";
// import { Link } from 'react-router-dom';
import { MuiOtpInput } from "mui-one-time-password-input";
import axios from "axios";
import { toast } from "react-toastify";
// material-ui
import {
  Button,
  InputAdornment,
  OutlinedInput,
  IconButton,
  // FormHelperText,
  Grid,
  Stack,
} from "@mui/material";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  useTheme,
} from "@mui/material";
import CompleteBackImage from "assets/images/Completeback.png";   
// // third-party
// import * as Yup from 'yup';
// import { Formik } from 'formik';

// project-imports
import AnimateButton from "components/@extended/AnimateButton";
import CircularProgress from "@mui/material/CircularProgress";
import { Eye, EyeSlash } from "iconsax-react";

// assets
import { VerifyOTP } from "store/action/index";
import { dispatch } from "store/index";
import { useNavigate } from "react-router-dom";

// ============================|| JWT - LOGIN ||============================ //

const AuthCreateUsrOtp = () => {

  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidOTP, setIsValidOTP] = useState(true);
let email= localStorage.getItem("CreateuserVerifyOtp")
  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const validateOTP = (inputOTP) => {
    return inputOTP.length === 6;
  };

  const handleSubmit = async () => {
    try {
      setIsValidOTP(validateOTP(otp));

      if (isValidOTP) {
        setLoading(true);
        // dispatch(
        //   VerifyOTP({
        //     otp: otp,
        //     setLoading: setLoading,
        //   })
        // );

let payload={otp: otp,email:email}
        axios.post('https://icertx.alisonstech.ae/Admin/public/api/verifyotp', payload)
        .then(response => {
          toast.success(`${email} verified successfully`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            bodyClassName: 'toastStyle',
        });
          setTimeout(()=>{
              navigate("/");
          },2000) 

        })
        .catch(error => {
          // Handle error
          setLoading(false)
          if(error.response.data?.message){
            toast.error(error.response.data?.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              bodyClassName: "toastStyle",
            })
      
          }
          console.error('Error:', error.response.data?.message);
        });


      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{ minHeight: "100vh", display: "flex", backgroundColor: "#f9f9f9" }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            backgroundImage: `url(${CompleteBackImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            [theme.breakpoints.down("md")]: {
              display: "none", // Hide on medium screens
            },
          }}
        >
          <Container maxWidth="sm">
            <Card
              sx={{
                m: "0 auto",
                paddingTop: "10%",
                margin: "15%",
                height: "75%",
                paddingLeft: "5%",
                paddingRight: "5%",
                borderRadius: "2%",
                borderBottom: "8px solid red",
                paddingBottom: "5%",
              }}
            >
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <MuiOtpInput
                        value={otp}
                        length={6}
                        onChange={handleChange}
                        TextFieldsProps={{ placeholder: "-" }}
                      />
                      {!isValidOTP && (
                        <p style={{ color: "red" }}>
                          Please enter a valid OTP.
                        </p>
                      )}
                    </Stack>
                  </Grid>
          
                  <Grid item xs={12}>
                    <div style={{ marginTop: 10 }}>
                      <AnimateButton>
                        <Button
                          onClick={handleSubmit}
                          disableElevation
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={loading} // Disable the button when loading
                        >
                          {loading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            "Verify OTP"
                          )}
                        </Button>
                      </AnimateButton>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default AuthCreateUsrOtp;
