import { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { dispatch } from "store/index";
import { DeleteMLS, GetMLSAll } from "store/action/index";
import { Link } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
import GetColumnSearchProps from "helper/searchHelper";
import { deleteButton } from "components/actionButtons/actionsButtons";

const AllMLSExclusive = ({ allMLSData }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(GetMLSAll({ setLoading }));
  }, []);

  const handleDelete = (userId) => {
    setLoading(true);
    let payload = {
        id: userId
      }
    setTimeout(() => {
      dispatch(DeleteMLS({
        payload, setLoading: setLoading
      }));
      // Set loading to false after a delay (adjust the delay as needed)
      setTimeout(() => {
        dispatch(GetMLSAll({ setLoading: setLoading }));
      }, 2400);
    }, 1000);
  };

  const columns = [
    {
      title: "ID",
      key: "index",
      render: (_, __, index) => index + 1,
      width: 70,
    },
    {
      title: "MLS Listing ID",
      dataIndex: "listing_id",
      key: "listing_id",
      ...GetColumnSearchProps("listing_id"),
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, record) => {
        return(
        <div style={{textAlign:'center'}}>

        {deleteButton("deactivate-property-type", record, handleDelete)}
        </div>
      )},
    },

  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              All MLS Exclusive Properties
            </span>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Link to={"/mls_exclusive/create-mls_exclusive"} style={{ textDecoration: 'none', color: '#FF264C' }}>
              <span
                disableElevation
                className="px-2 flex" style={{alignItems:"center"}}
                size="large"
              >
                <span className="mx-2">
                  <IoMdAddCircle color="#FF264C" size={26} />
                </span>
                Add MLS Exclusive
              </span>
            </Link>
          </Grid>

        </Grid>
      </div>
      <MainCard>
        <Table
          style={{ overflowX: "auto" }}
          pagination={false}

          dataSource={allMLSData} columns={columns} loading={loading}

        />
      </MainCard>
    </>
  );
};

const mapStateToProps = (state) => ({
  allMLSData: state?.GetAllCustomListing?.allMLSData,
});

export default connect(mapStateToProps)(AllMLSExclusive);
